
.toggleSwitch {
  position: relative;
  display: inline-block;
  cursor: pointer;
    height: 30px;
  -webkit-tap-highlight-color: transparent;
}
.toggleSwitch i {
  position: relative;
  top: 4px;
  display: inline-block;
  /*margin-right: 0.5rem;*/
  width: 60px;
  height: 25px;
  background-color: #e4e4e4;
  border-radius: 5px;
  vertical-align: text-bottom;
  transition: all 0.2s linear;
}

.toggleSwitchLeft {
    position: absolute;
    left: 0;
    top: 4px;
    width: calc((60px - 22px) / 2);
    height: 25px;
}


.toggleSwitch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 17px;
  background-color: #a9a9a9;
  border-radius: 3px;
  transform: translate3d(4px, 4px, 0);
  transition: all 0.2s ease-in-out;
}

.toggleTrue::after {
   background-color: #EA8811 !important;
}

.toggleFalse::after {
    background-color: #7f7f7f !important;
}

.toggleSwitch:active i::after {
  width: 28px;
  transform: translate3d(4px, 4px, 0);
}
.toggleSwitch:active input:checked + i::after {
  transform: translate3d(16px, 4px, 0);
}
.toggleSwitch input {
  position: absolute;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
}
.toggleSwitch input:checked + i {
  background-color: #e4e4e4;
}
.toggleSwitch input:checked + i::after {
  transform: translate3d(34px, 4px, 0);
}
.toggleSwitch.neutral i {
  background-color: #e4e4e4;
}
.toggleSwitch.neutral i::after {
  transform: translate3d(19px, 4px, 0);
}
