.rct-header-root {
    background: #F8F8FA;
    border-bottom: 1px solid #E2E1E4;
    border-radius: 15px 15px 0 0;
}

.react-calendar-timeline .rct-header-root {
    background: #F8F8FA;
    border-bottom: 1px solid #E2E1E4;
    border-radius: 15px 15px 0 0;
}

div.header-layout {
    display:flex;
    justify-content: center;
    align-items: center;
}

.react-calendar-timeline .rct-dateHeader-primary {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    color: #030303;
}

.react-calendar-timeline .rct-dateHeader {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    color: #030303;
    background: #F8F8FA;
    border: 0 hidden black;
    border-bottom: 1px solid #E2E1E4;
}

.react-calendar-timeline .rct-calendar-header {
    border: 0 hidden black;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {

    padding: 0;
}

.rct-item-content {
    border: 1px solid black;
    border-radius: 15px 15px 0 0;
    background-color: #F8F8FA;
    color: black;
}

div.rct-hl-even, div.rct-hl-odd  {
    border: 1px solid #E2E1E4 !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background-color: #FFFFFF;
}

.react-calendar-timeline .rct-item .rct-item-content {
    width: max-content;
}

.checkbox-hemoglobin .MuiSvgIcon-root {
    fill: #F0AF07;
}

.checkbox-kreatinin .MuiSvgIcon-root {
    fill: #9B51C6;
}

.checkbox-totalcholesterol .MuiSvgIcon-root {
    fill: #245B67;
}

.checkbox-ldlcholesterol .MuiSvgIcon-root {
    fill: #CF3A59;
}

.checkbox-hdlcholesterol .MuiSvgIcon-root {
    fill: #859EC2;
}

.checkbox-crp .MuiSvgIcon-root {
    fill: #777777;
}
.checkbox-leukozyten .MuiSvgIcon-root {
    fill: #3980a0;
}
.checkbox-mcv .MuiSvgIcon-root {
    fill: #5f7204;
}
.checkbox-rdw .MuiSvgIcon-root {
    fill: #2900db;
}
.checkbox-thrombozyten .MuiSvgIcon-root {
    fill: #a8206b;
}
.checkbox-gfr .MuiSvgIcon-root {
    fill: #7b883b;
}

.hemoglobin {
    background: rgba(240, 175, 7, 0.54);
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.kreatinin {
    background: rgba(155, 81, 198, 0.54);
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.totalcholesterol {
    background: rgba(36, 91, 103, 0.54);
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.ldlcholesterol {
    background: rgba(207, 58, 89, 0.54);
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.hdlcholesterol {
    background: rgba(133, 158, 194, 0.54);
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.crp {
    background: rgba(119, 119, 119, 0.54);
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.leukozyten {
    background: rgba(57, 128, 160, 0.54); /*3980a0*/
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.mcv {
    background: rgba(95, 114, 4, 0.54); /*5f7204*/
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.rdw {
    background: rgba(41, 0, 219, 0.54); /*2900db*/
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.thrombozyten {
    background: rgba(168, 32, 107, 0.54); /*a8206b*/
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.gfr {
    background: rgba(123, 136, 59, 0.54); /*7b883b*/
    min-height: 54px;
    border-bottom: 1px solid #E2E1E4;
}

.hemoglobin-item  {
    font-weight: bold;
    color: #BB8700;
}

.kreatinin-item  {
    font-weight: bold;
    color: #9B51C6;
}

.totalcholesterol-item  {
    font-weight: bold;
    color: #05343F;
}

.ldlcholesterol-item  {
    font-weight: bold;
    color: #B01938;
}

.hdlcholesterol-item  {
    font-weight: bold;
    color: #859EC2;
}

.crp-item  {
    font-weight: bold;
    color: #777777;
}

.leukozyten-item  {
    font-weight: bold;
    color: #3980a0;
}

.mcv-item  {
    font-weight: bold;
    color: #5f7204;
}

.rdw-item  {
    font-weight: bold;
    color: #2900db;
}

.thrombozyten-item  {
    font-weight: bold;
    color: #a8206b;
}

.gfr-item  {
    font-weight: bold;
    color: #7b883b;
}

.group-row {
    display: flex;
    align-items: center;
    padding-left: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #030303;
}

.timeline-component  {
    border: 1px solid #E2E1E4;
    border-radius: 15px 15px 15px 15px;
    width: calc(100vw - 450px - 120px);
}

.timeline-footer  {
    border: 0 solid #E2E1E4;
    border-radius: 0 0 15px 15px;
    min-height: 54px;
}

.timeline-checkbox {
    display: flex;
}
