.ql-editor {
    font-size: 15px;
    line-height: 1.8;
    min-width: 900px;
}
.ql-placeholder {
    border: 1px solid grey;
    border-radius: 6px;
    padding-right: 5px;
}
