table {
    border-style: hidden;
    border-spacing: 0px 5px;
}

th, td {
    border-style: hidden;
    text-align: left;
}

td:first-child,
th:first-child {
    border-radius: 4px 0px 0px 4px;
}

td:last-child,
th:last-child {
    border-radius: 0px 4px 4px 0px;
}
